import { GuestSMSConfigDialog } from "src/components/Homes/Guests/GuestSmsConfig"
import { usePostGuest } from "src/data/guests/queries/guestQueries"
import { IGuestPost } from "src/data/guests/types/guestTypes"

export function CreateGuestModal({
  homeId,
  timezone,
  onClose,
}: {
  homeId: string
  timezone: string
  onClose: () => void
}) {
  const postGuest = usePostGuest()

  function saveNewGuest(guest: IGuestPost) {
    postGuest.mutate(
      { guest, homeId },
      {
        onSuccess: () => {
          onClose()
        },
      }
    )
  }

  return (
    <GuestSMSConfigDialog
      open={true}
      homeId={homeId}
      timezone={timezone}
      onSave={saveNewGuest}
      onCancel={onClose}
      loading={postGuest.isLoading}
    />
  )
}
